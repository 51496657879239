<template>
  <div>
    <c-table
      ref="table1"
      title="화학물질 변경내역 목록"
      :gridHeight="gridHeight"
      :columns="gridChembase.columns"
      :data="history.chemBases"
    >
    </c-table>
    <!-- <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      height="550px"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <template v-if="tab.name === 'chembase'">
          <c-table
            ref="table1"
            title="화학물질 변경내역 목록"
            :gridHeight="gridHeight"
            :columns="gridChembase.columns"
            :data="history.chemBases"
          >
          </c-table>
        </template>
        <template v-else-if="tab.name === 'chem'">
          <c-table
            ref="table2"
            title="화학자재 변경내역 목록"
            :gridHeight="gridHeight"
            :columns="gridChem.columns"
            :data="history.chems"
          >
          </c-table>
        </template>
      </template>
    </c-tab> -->
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'regulDbUploadHistory',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          chmRegulDbUploadId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      history: {
        chmRegulDbUploadId: '',
        chemBases: [],
        chems: [],
      },
      gridChembase: {
        columns: [
          {
            name: 'chemName',
            field: 'chemName',
            label: '화학물질',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'chembase',
            field: 'chembase',
            label: '화학물질',
            child: [
              {
                name: 'flag',
                field: 'flag',
                label: '변경유형',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
              {
                name: 'changeContents',
                field: 'changeContents',
                label: '변경내용',
                align: 'left',
                style: 'width:250px',
                sortable: false,
                type: 'html',
              },
            ]
          },
          {
            name: 'regul',
            field: 'regul',
            label: '규제항목',
            child: [
              {
                name: 'billItemName',
                field: 'billItemName',
                label: '법규·규제',
                align: 'center',
                style: 'width:150px',
                sortable: false,
              },
              {
                name: 'flagRegul',
                field: 'flagRegul',
                label: '변경유형',
                align: 'center',
                style: 'width:50px',
                sortable: false,
              },
              {
                name: 'changeContentsRegul',
                field: 'changeContentsRegul',
                label: '변경내용',
                align: 'left',
                style: 'width:250px',
                sortable: false,
                type: 'html',
              },
            ]
          },
          {
            name: 'materialNames',
            field: 'materialNames',
            label: '영향화학자재',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
        ],
      },
      gridChem: {
        columns: [
          {
            name: 'materialNameEn',
            field: 'materialNameEn',
            label: '화학자재',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'chemName',
            field: 'chemName',
            label: '구성물질',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함유량',
            align: 'right',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'billItemName',
            field: 'billItemName',
            label: '법규·규제',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'flag',
            field: 'flag',
            label: '추가/수정/삭제',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'changeContents',
            field: 'changeContents',
            label: '변경내용',
            align: 'left',
            style: 'width:250px',
            sortable: false,
            type: 'html',
          },
        ],
      },
      tab: 'chembase',
      // tabItems: [
      //   { name: 'chembase', icon: 'science',  label: '화학물질 & 화학물질별 규제항목 변경내역' },
      //   { name: 'chem', icon: 'schema',  label: '영향 화학자재' },
      // ],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '70%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return '500px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.db.regulDbUpload.hostory.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        chmRegulDbUploadId: this.popupParam.chmRegulDbUploadId,
      }
      this.$http.request((_result) => {
        this.$_.extend(this.history, _result.data)
      },);
    },
  }
};
</script>
